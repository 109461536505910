import React, { useState } from 'react';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import ActionItemForm from 'components/BasicForms/ActionItemForm';
import { PropTypes } from 'prop-types';
import {
  SidebarFilterTabs,
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
} from '../../GlobalSideBar.styles';

const { spacing } = variables;

function ActionItemSidebar({ options }) {
  const {
    objectId,
    contentType,
    assignee_id: assigneeId,
    assignee_name: assigneeName,
    actionitem,
    onSuccess,
  } = options || {};
  // extract the content object
  const hidden = {};
  if (objectId) {
    hidden.objectId = objectId;
  }
  if (contentType) {
    hidden.contentType = contentType;
  }
  const [tab, setActiveTab] = useState('actionitem');
  const newActionItemData = {
    objectId,
    contentType,
    assignee_id: assigneeId,
    assignee_name: assigneeName,
  };

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <MD scaled paddingHorizontal bold color="#fff">
              Action Item
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterTabs>
        <TabList className="tabs no-border">
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('actionitem');
            }}
            selected={tab === 'actionitem'}
          >
            Action Item
          </Tab>
        </TabList>
      </SidebarFilterTabs>
      <SidebarFilterBody>
        {tab === 'actionitem' ? (
          <ActionItemForm
            actionitemId={actionitem?.id}
            data={actionitem || newActionItemData}
            tab={tab}
            onSuccess={onSuccess}
          />
        ) : null}
      </SidebarFilterBody>
    </SidebarContentWrapper>
  );
}

ActionItemSidebar.propTypes = {
  options: PropTypes.shape({
    objectId: PropTypes.string,
    contentType: PropTypes.number,
    assignee_id: PropTypes.string,
    assignee_name: PropTypes.string,
    actionitem: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    onSuccess: PropTypes.func,
  }),
};

export default ActionItemSidebar;
