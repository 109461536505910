import tree from 'state';
import * as effects from 'state/crm/effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';

// TODO: rename this to something like crmCursor
const crmCursor = tree.select(['crm']);

export async function createActionItem(values) {
  return asyncTreeRequester({
    func: effects.createActionItem.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'actionitem'],
  });
}

export async function updateActionItem(actionitemId, values) {
  return asyncTreeRequester({
    func: effects.updateActionItem.bind(this, actionitemId, values),
    cursor: crmCursor,
    path: ['crm', 'actionitem'],
  });
}

export async function deleteActionItem(values) {
  return asyncTreeRequester({
    func: effects.deleteActionItem.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'actionitem'],
  });
}

export async function createInteraction(values) {
  return asyncTreeRequester({
    func: effects.createInteraction.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'interaction'],
  });
}

export async function updateInteraction(interactionId, values) {
  return asyncTreeRequester({
    func: effects.updateInteraction.bind(this, interactionId, values),
    cursor: crmCursor,
    path: ['crm', 'interaction'],
  });
}

export async function deleteInteraction(values) {
  return asyncTreeRequester({
    func: effects.deleteInteraction.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'interaction'],
  });
}

export async function createEngagement(values) {
  return asyncTreeRequester({
    func: effects.createEngagement.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'engagement'],
  });
}

export async function updateEngagement(engagementId, values) {
  return asyncTreeRequester({
    func: effects.updateEngagement.bind(this, engagementId, values),
    cursor: crmCursor,
    path: ['crm', 'engagement'],
  });
}

export async function deleteEngagement(values) {
  return asyncTreeRequester({
    func: effects.deleteEngagement.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'engagement'],
  });
}

export async function createEngagementCompany(values) {
  return asyncTreeRequester({
    func: effects.createEngagementCompany.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'engagementcompany'],
  });
}

export async function deleteEngagementCompany(values) {
  return asyncTreeRequester({
    func: effects.deleteEngagementCompany.bind(this, values),
    cursor: crmCursor,
    path: ['crm', 'engagementcompany'],
  });
}

export async function setSidebarEngagement(engagement, { reset } = {}) {
  if (reset !== false) {
    crmCursor.set(['crm', 'engagement', 'sidebar'], {});
  }
}

export async function getInteractions({ pagination, filters, sorting } = {}) {
  return asyncTreeRequester({
    func: effects.getInteractions.bind(this),
    pagination,
    filters,
    sorting,
    handleResult: true,
  });
}

export async function getActionItems({ pagination, filters, sorting } = {}) {
  return asyncTreeRequester({
    func: effects.getActionItems.bind(this),
    pagination,
    filters,
    sorting,
    handleResult: true,
  });
}

export async function getEngagement(id) {
  return asyncTreeRequester({
    func: effects.getEngagement.bind(this, id),
    cursor: crmCursor,
    path: ['engagement', 'details'],
  });
}

// LIST TABLES

export async function getEngagementsList({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getEngagements.bind(this),
    pagination,
    filters,
    sorting,
    cursor: crmCursor,
    path: ['engagements', 'currentEngagements'],
  });
}

export async function getInteractionsList({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getInteractions.bind(this),
    pagination,
    filters,
    sorting,
    cursor: crmCursor,
    path: ['interactions', 'currentInteractions'],
  });
}

export async function getActionItemsList({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getActionItems.bind(this),
    pagination,
    filters,
    sorting,
    cursor: crmCursor,
    path: ['actionItems', 'currentActionItems'],
  });
}

export async function prependActionItemsList(newActionItem, options) {
  const { shouldPop } = options || {};
  const currentActionItems = crmCursor.get([
    'actionItems',
    'currentActionItems',
  ]);
  const actionItemsList = currentActionItems.result.docs;
  if (shouldPop) {
    actionItemsList.pop();
  }
  const newActionItemsList = [newActionItem, ...actionItemsList];
  const newActionItems = {
    ...currentActionItems,
    result: {
      ...currentActionItems.result,
      docs: newActionItemsList,
      total: shouldPop
        ? currentActionItems.result.total + 1
        : currentActionItems.result.total,
    },
  };
  crmCursor.set(['actionItems', 'currentActionItems'], newActionItems);
}

//

export async function createObjectPersonnel(values) {
  return asyncTreeRequester({
    func: effects.createObjectPersonnel.bind(this, values),
    handleResult: true,
  });
}

export async function getObjectPersonnel(values) {
  return asyncTreeRequester({
    func: effects.getObjectPersonnel.bind(this, values),
    handleResult: true,
  });
}

export async function deleteObjectPersonnel(values) {
  return asyncTreeRequester({
    func: effects.deleteObjectPersonnel.bind(this, values),
    handleResult: true,
  });
}
