import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function getListingDetails(id, refresh) {
  const url = refresh
    ? `${BASE}/api/p/listing/${id}/?refresh=1`
    : `${BASE}/api/p/listing/${id}/`;
  const response = await fetchJson(url, {
    method: 'GET',
  });
  return response;
}

export async function getSimilarListings({ criteria, id }, { filters }) {
  const body = { criteria, id, ...(filters || {}) };
  const response = await fetchJson(`${BASE}/api/p/listing/similar/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function getListings(
  criteriaArgs,
  { filters = {}, pagination = {}, sorting = {} }
) {
  const { criteria = {} } = criteriaArgs || {};
  const body = { ...filters, ...criteria, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/p/listing/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function getCuratedListingsListHtml(ids, title) {
  const body = { listing_ids: ids, custom_name: title };
  const response = await fetchJson(`${BASE}/api/p/listing/curatedlist/html/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function createCuratedListingsList(ids, title) {
  const body = { listing_ids: ids, custom_name: title};
  const response = await fetchJson(`${BASE}/api/p/listing/curatedlist/pdf/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function getCuratedListings(uuid) {
  const url = `${BASE}/api/p/listing/curatedlist/pdf/${uuid}/`;
  const response = await fetchJson(url, {
    method: 'GET',
  });
  return response;
}
