import {
  mapValues,
  some,
  every,
  find,
  pickBy,
  has,
  get,
  reduce,
  map,
  isArray,
  filter,
  isNaN,
  reject,
  includes,
  cloneDeep,
  isBoolean,
} from 'lodash';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

export function valueExtractor(value) {
  // DUPLICATE FUNCTION IN VALIDATION_ITEMS
  const min = get(value, 'min.value');
  const max = get(value, 'max.value');
  let _value;
  if (max || min) {
    _value = '';
  } else if (has(value, 'value')) {
    _value = value.value;
  } else {
    _value = value || '';
  }
  const totalValues = pickBy(
    {
      min,
      max,
      value: _value,
    },
    (v) => !!v || v === false
  );

  return totalValues;
}

export function baseValidation(args, required, field, options = {}) {
  const _args = args || {};
  const range = get(_args, 'min.value') || get(_args, 'max.value');
  let value = field ? _args[field] : _args.value;

  if (!value && !required && (!options.forceValidation || !value)) {
    return true;
  }

  if (!value && value !== false) {
    if (get(_args, 'min.value') && get(_args, 'max.value')) {
      return Number(_args.min.value) < Number(_args.max.value);
    }
    if (range) {
      value = range;
    } else {
      value =
        _args.value || _args.value === false || _args.value === 0
          ? _args.value
          : _args.length;
    }
  }

  if (options.isNumber) {
    return !isNaN(value) && (options.isPositive ? Number(value) > -1 : true);
  }

  return value || value === false;
}

export function onboardingValueExtractor(fieldObj) {
  return reduce(
    fieldObj,
    (accum, bi) => {
      const r = reduce(
        get(bi, 'fields'),
        (acc, field, name) => {
          const transformer = field.transformer || ((v) => v);

          const _name = field.serverName ? field.serverName : name;
          const valueIsArray = isArray(get(field, 'value'));
          const extractedValue = valueExtractor(get(field, 'value'));
          let endValue;
          let otherValues;

          if (valueIsArray) {
            const scopedValues = field.ignoreCustomFieldValidation
              ? get(field, 'value')
              : reject(get(field, 'value'), 'customValue'); // remove other options with customValue and place them in _other
            endValue = map(scopedValues, (v) => transformer(v.value));
            acc[_name] = endValue;

            otherValues = filter(get(field, 'value'), (f) =>
              transformer(f.customValue)
            ); // insert custom values into other option
            otherValues = map(otherValues, (v) => transformer(v.value));
            if (field.customValues) {
              acc[`${_name}_other`] = otherValues.length ? otherValues : [];
            }
          } else {
            endValue =
              get(field, 'value.endValue') ||
              get(field, 'value.place_id') ||
              get(field, 'value.value');
            endValue = get(field, 'value.customValue') ? null : endValue;
            // undefined does not get sent to server. may not need this and always send null if no value
            if (!isBoolean(endValue) && !endValue && endValue !== '') {
              if (get(field, 'clearable')) {
                endValue = null;
              } else {
                endValue = undefined;
              }
            }
            // only send empty string or null if the field has been cleared
            if (endValue === '' && get(field, 'dirty')) {
              if (get(field, 'nullable')) {
                endValue = null;
              }
            } else if (endValue === '') {
              endValue = undefined;
            }
            acc[_name] = transformer(endValue);

            if (field.customValues) {
              acc[`${_name}_other`] = transformer(
                get(field, 'value.customValue')
                  ? get(field, 'value.value')
                  : null
              );
            }
          }

          if (
            field.uomKey &&
            (extractedValue.min || extractedValue.max || extractedValue.value)
          ) {
            acc[field.uomKey] = get(field.uom, 'value');
          }

          if (extractedValue.min || extractedValue.max) {
            acc[`${_name}_min`] = extractedValue.min;
            acc[`${_name}_max`] = extractedValue.max;
          }
          return acc;
        },
        {}
      );

      const _accum = { ...accum, ...r };
      return _accum;
    },
    {}
  );
}

export function isFormValid(fieldObj) {
  return every(fieldObj, (bi) =>
    every(get(bi, 'fields'), (field) =>
      (field.required ||
        (field.forceValidation && get(field.value, 'value'))) &&
      !field.disabled
        ? field.valid
        : true
    )
  );
}

export function isFormDirty(fieldObj) {
  return some(fieldObj, (bi) => some(get(bi, 'fields'), 'dirty'));
}

export function resetDirty(fieldObj) {
  return mapValues(fieldObj, (v) => {
    if (v && v.fields) {
      return {
        ...v,
        fields: mapValues(v.fields, (fv) => ({ ...fv, dirty: false })),
      };
    }
    return v;
  });
}

export function onboardingReverser(
  config,
  serverValues,
  { forceLabel = {} } = {}
) {
  const generateValues = (category, _serverValues) => {
    const f = reduce(
      category.fields, // if you're getting a "cannot read properties of undefined" error here, make sure your config has a value in the "config.id" field in the transformer
      (accum, value, key) => {
        // This location flag is pretty arbitrary, so added a last resort to check the config location boolean - this may be the only way we want to check this going forward
        // TODO Check for forms that might be broken by returning true here for config { location: true }
        const _accum = cloneDeep(accum);
        const _value = cloneDeep(value);
        const isLocation =
          key === 'business_location' ||
          key === 'location' ||
          key === 'location_description' ||
          key === 'supply_location_place_id' ||
          key === 'need_location_place_id' ||
          key === 'consignment_location_place_id' ||
          key === 'ex_marketing_location_place_id' ||
          category.fields[key].location;
        // think we can get rid of  key}s` logic. endpoint was fixed.
        let serverValExact =
          _serverValues[_value.serverName || key] !== undefined
            ? _serverValues[_value.serverName || key]
            : _serverValues[`${_value.serverName || key}s`];
        serverValExact = serverValExact === 0 ? '0' : serverValExact; // FORCE VALUE

        let serverValOther =
          _serverValues[`${key}_other`] !== undefined
            ? _serverValues[`${key}_other`]
            : _serverValues[`${key}s_other`];
        serverValOther = serverValOther === 0 ? '0' : serverValOther; // FORCE VALUE

        let serverValMin = !isNaN(_serverValues[`${key}_min`])
          ? serverValues[`${key}_min`]
          : null;
        serverValMin = serverValMin === 0 ? '0' : serverValMin; // FORCE VALUE

        let serverValMax = !isNaN(_serverValues[`${key}_max`])
          ? _serverValues[`${key}_max`]
          : null;
        serverValMax = serverValMax === 0 ? '0' : serverValMax; // FORCE VALUE

        const serverValName = _serverValues[`${key}_name`];

        let serverVal =
          serverValExact &&
          serverValOther &&
          isArray(serverValExact) &&
          isArray(serverValOther)
            ? [...serverValExact, ...serverValOther]
            : serverValExact || serverValOther;
        // A false boolean is a valid value
        if (serverValExact === false) serverVal = false;
        if (
          !serverVal &&
          !serverValMin &&
          !serverValMax &&
          !isLocation &&
          _serverValues.google_address
        ) {
          _accum[key] = _value;
          return _accum;
        }

        let newValue = _value.multiple || _value.multiSelect ? [] : {};
        if (isArray(serverVal) || category.multiple) {
          newValue = map(serverVal, (v) => {
            const originalLabel = get(v, 'name');
            const _v = get(v, 'id') || get(v, 'value') || v;
            const found = _value.buttons
              ? find(_value.buttons, { value: _v })
              : find(_value.items, { value: _v });
            const label = get(found, 'label');
            if (label) {
              return {
                value: _v,
                label: get(find(_value.items, { value: _v }), 'label', _v),
              };
            }
            let labelValue = _v;
            if (_v.name) {
              labelValue = _v.name;
            }
            if (originalLabel) {
              labelValue = originalLabel;
            }
            return {
              value: _v.id ? _v.id : _v,
              label: labelValue,
              customValue: !_value.search,
            };
          });
        } else if (isLocation) {
          // For cases with more than one address
          const addressPath = _value.addressPath
            ? _value.addressPath
            : 'google_address';
          newValue.place_id =
            get(_serverValues[addressPath], 'place_id') ||
            _serverValues.place_id ||
            _serverValues.supply_location_place_id;
          newValue.value =
            get(_serverValues[addressPath], 'formatted_address') ||
            _serverValues.location_description ||
            _serverValues.supply_location_description;
          newValue.label =
            get(_serverValues[addressPath], 'formatted_address') ||
            _serverValues.location_description ||
            _serverValues.supply_location_description;
        } else if (includes(key, 'phone')) {
          newValue.value = formatPhoneNumberIntl(serverVal);
          newValue.label = formatPhoneNumberIntl(serverVal);
        } else if (serverValMax && serverValMin) {
          newValue = {
            min: { value: serverValMin, label: serverValMin },
            max: { value: serverValMax, label: serverValMax },
          };
        } else {
          const serverLabel = get(
            find(_value.items, { value: serverVal }),
            'label'
          );
          if (forceLabel[key] && !serverLabel) {
            newValue.value = '';
            newValue.label = '';
          } else {
            newValue.value = serverVal;
            newValue.label = serverLabel || serverValName || serverVal;
            newValue.customValue = !!serverValOther && !_value.search;
          }
        }

        if (serverValOther && _value.buttons) {
          if (isArray(serverVal)) {
            const otherButtonOptions = map(serverValOther, (sv) => ({
              label: sv,
              value: sv,
              customValue: true,
            }));
            _value.buttons = [..._value.buttons, ...otherButtonOptions];
          }
        }

        _accum[key] = { ...value, value: newValue };
        // A false boolean is a valid value
        _accum[key].valid =
          !!newValue &&
          (!!get(newValue, 'value') || get(newValue, 'value') === false)
            ? true
            : undefined;

        // SET UOM
        if (value.uomKey && _serverValues[value.uomKey || `${key}_uom`]) {
          const uomValue = _serverValues[value.uomKey || `${key}_uom`];
          const uomTitle =
            get(find(value.uomOptions, { value: uomValue }), 'label') ||
            uomValue;
          _accum[key].uom = { value: uomValue, label: uomTitle };
        }

        // SET RANGE OPERATOR
        if (value.operator && serverValMin && serverValMax) {
          _accum[key].operator = { value: 'range', label: 'Range' };
        }

        return _accum;
      },
      {}
    );
    return { ...category, fields: f };
  };

  return reduce(
    config,
    (accum, category, key) => {
      const _accum = cloneDeep(accum);
      const values = generateValues(category, serverValues);
      if (category.fields) {
        _accum[key] = values;
      } else {
        // when no fields such as UI or titl
        _accum[key] = category;
      }
      return _accum;
    },
    {}
  );
}

export function getNullableValueOrArg(value, arg) {
  return value || value === null ? value : arg;
}
