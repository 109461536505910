import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import { AccordianWrapper } from 'theme/Global.styles';
import { MD, SM } from '@zendeskgarden/react-typography';
import { Message, Field } from '@zendeskgarden/react-forms';
import { Row, Col } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';
import {
  get,
  reduce,
  sortBy,
  map,
  filter,
  find,
  toLower,
  includes,
  trim,
  replace,
} from 'lodash';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import { FormWrapper } from 'theme/AuthForms.styles';
import { attributeShape } from 'propTypesObjects';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getClientId } from 'utility/hasAccount';

import {
  addAttributesToContentType,
  getAttributeCategory,
  getAttributesForContentType,
  getAttributesForChildContentType,
  deleteAttributesToContentType,
} from 'state/attributes/actions';

const { custom_navy: customNavy, custom_dark_grey: customDarkGrey } = variables;

export function flattenCat(categories) {
  return reduce(
    categories,
    (accum, category) => {
      const subcatVal = reduce(
        category.sub_category,
        (a, sc) => [...a, ...(sc.value || [])],
        []
      );
      const allValues = [...(category.value || []), ...subcatVal];
      const currentAccum = [...accum, ...allValues];
      return currentAccum;
    },
    []
  );
}

function getSelectedString(category, selected) {
  const subcatVal = reduce(
    category.sub_category,
    (a, sc) => [...a, ...(sc.value || [])],
    []
  );
  const allValues = [...(category.value || []), ...subcatVal];
  const numSelected = filter(allValues, (v) =>
    find(selected, { value_id: v.id })
  );
  return `${numSelected.length} of ${allValues.length}`;
}

/**
 * @param externalSave {function} Pass a function wrapped in useCallback here to prevent refetching the static Attributes list
 */

function AttributesForm({
  contentType,
  objectId,
  clientId,
  forChild,
  externalSave,
  externalSelected,
}) {
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState('');
  const [data, setData] = useState();
  const [listStatus, setListStatus] = useState();
  const [filterData, setFiltered] = useState([]);
  const [status, setStatus] = useState({});
  const currentClientId = clientId || getClientId();

  useEffect(() => {
    setSelected(externalSelected);
  }, [externalSelected]);

  const getValues = useCallback(async () => {
    if (!externalSave) {
      let values;
      if (forChild) {
        values = await getAttributesForChildContentType({
          content_type_id: contentType,
          object_id: objectId,
        });
      } else {
        values = await getAttributesForContentType({
          content_type_id: contentType,
          object_id: objectId,
        });
      }
      setSelected(values);
    }
  }, [contentType, externalSave, forChild, objectId]);

  const setSelectedValue = useCallback(
    async ({ currentValue, selectedValue }) => {
      setStatus({ ...status, [`id_${currentValue.id}`]: { loading: true } });
      if (selectedValue) {
        let deleteAttribute = {};
        if (externalSave) {
          externalSave({ value: currentValue, selectedValue });
        } else {
          deleteAttribute = await deleteAttributesToContentType(
            selectedValue.id
          );
        }
        if (deleteAttribute.error) {
          setStatus({
            ...status,
            [`id_${currentValue.id}`]: {
              error: `${currentValue.value} - ${deleteAttribute.error}`,
            },
          });
        } else {
          setStatus({ ...status, [`id_${currentValue.id}`]: null });
          getValues();
        }
      } else {
        let addAttribute = {};
        if (externalSave) {
          externalSave({ value: currentValue, selectedValue });
        } else {
          addAttribute = await addAttributesToContentType({
            value_list: [currentValue.id],
            content_type: contentType,
            object_id: objectId,
            client: currentClientId,
          });
        }
        if (addAttribute.error) {
          setStatus({
            ...status,
            [`id_${currentValue.id}`]: {
              error: `${currentValue.value} - ${addAttribute.error}`,
            },
          });
        } else {
          setStatus({ ...status, [`id_${currentValue.id}`]: null });
          getValues();
        }
      }
    },
    [contentType, currentClientId, externalSave, getValues, objectId, status]
  );

  useEffect(() => {
    if (value) {
      const filteredResults = filter(flattenCat(data), (d) =>
        includes(toLower(d.value), toLower(value))
      );
      setFiltered(filteredResults);
    } else {
      setFiltered(null);
    }
  }, [value, data]);

  useEffect(() => {
    async function getData() {
      setListStatus({ loading: true });
      const categories = await getAttributeCategory();
      await getValues();
      if (categories && categories.length) {
        setData(categories);
      }
      setListStatus({ loading: false });
    }
    getData();
  }, [getValues]);

  const errors = map(
    filter(status, (d) => d && d.error),
    'error'
  );

  return (
    <div>
      <div>
        <div style={{ margin: 0, padding: 0, position: 'relative' }}>
          <Icon
            icon="icon-search-left"
            color="#67778d"
            style={{
              position: 'absolute',
              zIndex: `2`,
              fontSize: `18px`,
              left: '20px',
              top: '12px',
              color: '#ccc',
            }}
          />
          <input
            type="text"
            id="name"
            // LINT OVERRIDE #7
            // autoFocus is preferred for user experience
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            autoComplete="off"
            name="name"
            onChange={(e) => setValue(e.currentTarget.value)}
            style={{
              outline: 'none',
              width: '100%',
              padding: 0,
              paddingLeft: '50px',
              borderRadius: '0',
              border: 'none',
              borderBottom: '1px solid #e1e5ec',
              height: '45px',
            }}
            placeholder="Search For Attributes"
          />
        </div>
      </div>
      <div style={{ padding: '0 10px 1px 10px' }}>
        {listStatus && listStatus.loading && !get(data, 'length') ? (
          <div style={{ padding: '30px 0px' }}>
            <Spinner />
          </div>
        ) : null}
        {errors.length ? (
          <Field style={{ padding: 10 }}>
            {map(errors, (d) => (
              <Message key={d} validation="error">
                {d}
              </Message>
            ))}
          </Field>
        ) : null}
        <Row>
          {value && filterData ? (
            <Col style={{ padding: '10px' }}>
              {map(sortBy(filterData), (item) => {
                const selectedValue = find(selected, { value_id: item.id });
                return (
                  <RenderButton
                    includeCategory
                    status={status}
                    key={item.id}
                    item={item}
                    isSelected={!!selectedValue}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                  />
                );
              })}
            </Col>
          ) : (
            <Col>
              <FormWrapper spacing="0px">
                {data && (
                  <AccordianWrapper style={{ margin: '0 -5px' }}>
                    <Accordion allowMultipleExpanded allowZeroExpanded>
                      <AccordionItem key="selected-attributes">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <Flex full alignCenter justifyBetween>
                              <MD navy semibold as="span">
                                Selected Attributes
                              </MD>
                              <SM slate light>
                                {`${selected?.length || 0} selected`}
                              </SM>
                            </Flex>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {selected?.length ? (
                            map(selected, (item) => (
                              <RenderButton
                                includeCategory
                                status={status}
                                key={item.value}
                                item={item}
                                isSelected
                                selectedValue={item}
                                setSelectedValue={setSelectedValue}
                              />
                            ))
                          ) : (
                            <SM slate light>
                              Selected Attributes can be reviewed here.
                            </SM>
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                      {map(data, (d) => (
                        <AccordionItem
                          key={d.name && trim(replace(d.name, ' ', '-'))}
                        >
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <Flex full alignCenter justifyBetween>
                                <MD navy semibold as="span">
                                  {d.name}
                                </MD>
                                <SM slate light>
                                  {getSelectedString(d, selected)}
                                </SM>
                              </Flex>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            {map(sortBy(d.value), (item) => {
                              const selectedValue = find(selected, {
                                value_id: item.id,
                              });
                              return (
                                <RenderButton
                                  status={status}
                                  key={item.value}
                                  item={item}
                                  isSelected={!!selectedValue}
                                  selectedValue={selectedValue}
                                  setSelectedValue={setSelectedValue}
                                />
                              );
                            })}

                            {map(sortBy(d.sub_category), (sc, i) => (
                              <div key={`${sc.name}-${i}`}>
                                <MD
                                  style={{ paddingLeft: '10px' }}
                                  bold
                                  paddingBottomXs
                                  paddingTopMd
                                >
                                  {sc.name}
                                </MD>
                                {map(sortBy(sc.value), (item) => {
                                  const selectedValue = find(selected, {
                                    value_id: item.id,
                                  });
                                  return (
                                    <RenderButton
                                      status={status}
                                      key={item.value}
                                      item={item}
                                      isSelected={!!selectedValue}
                                      selectedValue={selectedValue}
                                      setSelectedValue={setSelectedValue}
                                    />
                                  );
                                })}
                              </div>
                            ))}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordianWrapper>
                )}
              </FormWrapper>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

AttributesForm.propTypes = {
  contentType: PropTypes.number,
  objectId: PropTypes.number,
  clientId: PropTypes.number,
  forChild: PropTypes.bool,
  externalSave: PropTypes.func,
  externalSelected: PropTypes.arrayOf(attributeShape),
};

function RenderButton({
  item,
  isSelected,
  selectedValue,
  setSelectedValue,
  status,
  includeCategory,
}) {
  const errorStyle =
    status[`id_${item.id}`] && status[`id_${item.id}`].error
      ? { borderColor: 'red' }
      : {};
  const loading = status[`id_${item.id}`] && status[`id_${item.id}`].loading;
  return (
    <ZendeskButton
      key={item.id}
      bold
      size="small"
      style={{
        position: 'relative',
        margin: '5px',
        padding: '0 10px',
        minWidth: '0',
        ...errorStyle,
      }}
      hoverColor="#d4dded"
      hoverTextColor={customNavy}
      onClick={() => {
        // We need to send the selected value here because it contains the ID needed to delete the attribute from this content object.
        setSelectedValue({ currentValue: item, selectedValue });
      }}
      color={!isSelected ? customDarkGrey : undefined}
      whiteOutline={!isSelected}
      disabled={loading}
      borderColor="rgba(0,0,0,0.1)"
      navy={isSelected}
    >
      <span style={loading ? { visibility: 'hidden' } : {}}>
        {item.value}{' '}
        {includeCategory && `(${item.sub_category_name || item.category_name})`}
      </span>
      {loading ? (
        <div style={{ position: 'absolute', left: 0, right: 0, top: '9px' }}>
          <Spinner size="7px" />
        </div>
      ) : null}
    </ZendeskButton>
  );
}

RenderButton.propTypes = {
  item: attributeShape,
  selectedValue: attributeShape,
  isSelected: PropTypes.shape({
    active_status: PropTypes.number,
    category_id: PropTypes.number,
    category_name: PropTypes.string,
    client: PropTypes.number,
    content_type: PropTypes.number,
    create_timestamp: PropTypes.string,
    id: PropTypes.number,
    object_id: PropTypes.number,
    update_timestamp: PropTypes.string,
    value: PropTypes.string,
    value_id: PropTypes.number,
  }),
  setSelectedValue: PropTypes.func,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  status: PropTypes.object,
  includeCategory: PropTypes.bool,
};

export default AttributesForm;
