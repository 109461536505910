/* eslint-disable camelcase */
import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';

import { getCustomers as getAttributeCustomers } from 'state/customers/effects';
import { getCompanies as getAttributeCompanies } from 'state/companies/effects';
import {
  getOpportunities as getAttributeOpportunities,
  getItemOpportunities as getAttributeItemOpportunities,
} from 'state/opportunities/effects';
import {
  getSupplyInterestsGroups,
  getSupplyInterestsItems as getAttributeSupplyInterests,
} from 'state/supplyInterests/effects';
import { downloadFileFromBlob } from 'utility/downloadFile';
import * as effects from './effects';

const attributesCursor = tree.select(['attributes']);

export async function getAttributeDetails(id) {
  return asyncTreeRequester({
    func: effects.getAttributeCategoryDetails.bind(this, id),
    cursor: attributesCursor,
    path: ['activeAttribute', id],
  });
}

export async function getAttributeCategoryList({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getAttributeCategoryList.bind(this), // MOVE TO THIS WHEN API effects.getAttributeCategoryList.bind(this),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['list'],
  });
}

export async function exportAttributeCategoryList({ filters, sorting } = {}) {
  return asyncTreeRequester({
    func: effects.exportAttributeCategoryList.bind(this),
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['list', 'export'],
    onSuccess: (blob) => {
      downloadFileFromBlob(blob, 'attribute_category_list.xlsx');
    },
  });
}

export async function getAttributeCategory() {
  return asyncTreeRequester({
    func: effects.getAttributeCategory.bind(this),
    cursor: attributesCursor,
    path: ['categories'],
  });
}

export async function createAttributeCategory(data) {
  const result = asyncTreeRequester({
    func: effects.createAttributeCategory.bind(this, data),
    cursor: attributesCursor,
    path: ['categories', 'new'],
  });
  getAttributeCategory();
  return result;
}

export async function updateAttributeCategory(id, data) {
  const result = asyncTreeRequester({
    func: effects.updateAttributeCategory.bind(this, id, data),
    cursor: attributesCursor,
    path: ['categories', id],
  });
  getAttributeCategory();
  return result;
}

export async function deleteAttributeCategory(id) {
  const result = asyncTreeRequester({
    func: effects.deleteAttributeCategory.bind(this, id),
    cursor: attributesCursor,
    path: ['categories', 'delete'],
  });
  getAttributeCategory();
  return result;
}

export async function getAttributeSubCategory() {
  return asyncTreeRequester({
    func: effects.getAttributeSubCategory.bind(this),
    cursor: attributesCursor,
    path: ['subCategory'],
  });
}

export async function createAttributeSubCategory(data) {
  const result = asyncTreeRequester({
    func: effects.createAttributeSubCategory.bind(this, data),
    cursor: attributesCursor,
    path: ['subCategory', 'new'],
  });
  getAttributeCategory();
  return result;
}

export async function updateAttributeSubCategory(id, data) {
  const result = asyncTreeRequester({
    func: effects.updateAttributeSubCategory.bind(this, id, data),
    cursor: attributesCursor,
    path: ['subCategory', id],
  });
  getAttributeCategory();
  return result;
}

export async function deleteAttributeSubCategory(id) {
  const result = asyncTreeRequester({
    func: effects.deleteAttributeSubCategory.bind(this, id),
    cursor: attributesCursor,
    path: ['subCategory', 'delete'],
  });
  getAttributeCategory();
  return result;
}

export async function getAttributeCategoryValue() {
  return asyncTreeRequester({
    func: effects.getAttributeCategoryValue.bind(this),
    cursor: attributesCursor,
    path: ['values'],
  });
}

export async function createAttributeCategoryValue(data) {
  const result = asyncTreeRequester({
    func: effects.createAttributeCategoryValue.bind(this, data),
    cursor: attributesCursor,
    path: ['values', 'new'],
  });
  getAttributeCategory();
  return result;
}

export async function updateAttributeCategoryValue(id, data) {
  const result = asyncTreeRequester({
    func: effects.updateAttributeCategoryValue.bind(this, id, data),
    cursor: attributesCursor,
    path: ['values', 'new'],
  });
  getAttributeCategory();
  return result;
}

export async function deleteAttributeCategoryValue(id) {
  const result = asyncTreeRequester({
    func: effects.deleteAttributeCategoryValue.bind(this, id),
    cursor: attributesCursor,
    path: ['values', 'delete'],
  });
  getAttributeCategory();
  return result;
}

export async function getAttributesForContentType({
  content_type_id,
  object_id,
}) {
  return asyncTreeRequester({
    func: effects.getAttributesForContentType.bind(this, {
      content_type_id,
      object_id,
    }),
    cursor: attributesCursor,
    path: ['current'],
  });
}

// For a special case where there are two attributes forms on the same page
export async function getAttributesForChildContentType({
  content_type_id,
  object_id,
}) {
  return asyncTreeRequester({
    func: effects.getAttributesForContentType.bind(this, {
      content_type_id,
      object_id,
    }),
    cursor: attributesCursor,
    path: ['current', 'child'],
  });
}

export async function addAttributesToContentType({
  content_type,
  object_id,
  value_list,
  client,
}) {
  return asyncTreeRequester({
    func: effects.addAttributesToContentType.bind(this, {
      content_type,
      object_id,
      value_list,
      client,
    }),
    cursor: attributesCursor,
    handleResult: true,
  });
}

export async function deleteAttributesToContentType(id) {
  return asyncTreeRequester({
    func: effects.deleteAttributesToContentType.bind(this, id),
    cursor: attributesCursor,
    handleResult: true,
  });
}

export async function getCustomers({ pagination, filters, sorting } = {}) {
  return asyncTreeRequester({
    func: getAttributeCustomers.bind(this),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'customers'],
  });
}

export async function getCompanies({ pagination, filters, sorting } = {}) {
  return asyncTreeRequester({
    func: getAttributeCompanies.bind(this),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'companies'],
  });
}

export async function getOpportunities({ pagination, filters, sorting } = {}) {
  return asyncTreeRequester({
    func: getAttributeOpportunities.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'opportunities'],
  });
}

export async function getItemOpportunities({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: getAttributeItemOpportunities.bind(this),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'itemOpportunities'],
  });
}

export async function getSupplyInterests({
  pagination,
  filters,
  sorting = {},
} = {}) {
  return asyncTreeRequester({
    func: getAttributeSupplyInterests.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'supplyInterests'],
  });
}

export async function getSupplyInterestsNested({
  pagination,
  filters,
  sorting = {},
} = {}) {
  return asyncTreeRequester({
    func: getSupplyInterestsGroups.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'supplyInterestsNested'],
  });
}

export async function getOpportunitiesNested({
  pagination,
  filters,
  sorting = {},
} = {}) {
  return asyncTreeRequester({
    func: getAttributeSupplyInterests.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: attributesCursor,
    path: ['agg', 'opportunitiesNested'],
  });
}

export async function getItems(data) {
  return asyncTreeRequester({
    func: effects.getAttributeItems.bind(this, data),
    cursor: attributesCursor,
    handleResult: true,
  });
}

export async function saveItem({ spreadFields = {}, criteria, id } = {}) {
  return asyncTreeRequester({
    func: effects.saveAttributeItem.bind(this, {
      ...spreadFields,
      criteria,
      id,
    }),
    cursor: attributesCursor,
    path: ['items'],
  });
}

export async function deleteItem(item) {
  return asyncTreeRequester({
    func: effects.deleteAttributeItem.bind(this, item),
    cursor: attributesCursor,
    path: ['items'],
  });
}

export async function getAllData(id) {
  getCustomers({
    pagination: { limit: 10 },
    filters: { attribute_values: [id] },
  });
  getCompanies({
    pagination: { limit: 10 },
    filters: { attribute_values: [id] },
  });
  getOpportunities({
    pagination: { limit: 10 },
    filters: { attribute_values: [id] },
  });
  getSupplyInterests({
    pagination: { limit: 10 },
    filters: { attribute_values: [id] },
  });

  getOpportunitiesNested({
    pagination: { limit: 10 },
    filters: { attribute_values: [id] },
  });
  getSupplyInterestsNested({
    pagination: { limit: 10 },
    filters: { attribute_values: [id] },
  });
}
